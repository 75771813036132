import { Flex, Heading, LinkBox, Stack, StackProps, Text } from "@biblioteksentralen/react";
import { getFirstThreePublicationsWithImage, ResolvedListSummary } from "@libry-content/common";
import { useId } from "react";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { Edit } from "../editInSanity/EditInSanity";
import { withErrorBoundary } from "../errorPages/withErrorBoundary";
import { BookListCoversFan } from "./CoversFan";
import { MediaTypesAndTargetAudiences } from "./MediaTypesAndTargetAudiences";
import { useSitePalette } from "../../utils/useSitePalette";

interface Props extends StackProps {
  list: ResolvedListSummary;
  headingSize?: "h2" | "h3";
}

const BookListPreview = ({ list, headingSize = "h3", ...stackProps }: Props) => {
  const { ts } = useTranslation();
  const paletteColor = useSitePalette();
  const publicationsWithImage = getFirstThreePublicationsWithImage(list?.publications);
  const headingId = useId();

  return (
    <Stack direction="column" aria-labelledby={headingId} {...stackProps}>
      <LinkBox>
        <Stack direction="column" role="group">
          {publicationsWithImage && (
            <BookListCoversFan
              publications={publicationsWithImage}
              {...paletteColor.colors.card.css}
              height={{ base: "13rem", lg: "14rem" }}
              maxWidth="15rem"
              borderRadius="0.5rem"
            />
          )}
          <Flex direction="column" gap="0.2rem">
            <MediaTypesAndTargetAudiences mediaTypes={list.mediaTypes} targetAudiences={list.targetAudiences} />
            <Heading as={headingSize} size="md" id={headingId}>
              <InternalLinkOverlay href={getPath(list)} _groupHover={{ textDecoration: "underline" }}>
                {ts(list.title)}
              </InternalLinkOverlay>
            </Heading>
            <Text fontSize="sm">{ts(list.teaser)}</Text>
          </Flex>
        </Stack>
      </LinkBox>
      <Edit doc={list} />
    </Stack>
  );
};

export default withErrorBoundary(BookListPreview, "BookListPreview");
